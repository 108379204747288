import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './OfflineUserProfiles.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import ContentWithThumbnail from '../../../components/ContentWithThumbnail/ContentWithThumbnail';
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail';
import { toInitials } from '../../../utils/string';
import colors from '../../../globals/colors';
import { deleteOfflineProfiles } from '../../../redux/actions/offlineContentPlayer.js';
import OfflineModal from '../OfflineModal/OfflineModal.js';
import { GlyphIcon, EmblemSize, EmblemShape } from '../../../components/IconEmblem/IconEmblem.js';
import OfflineUpdate from '../OfflineUpdate/OfflineUpdate';

function OfflineUserProfiles({
  localizedContent: { productDownload: offlineContent },
  storedOfflineProfiles,
  deleteOfflineProfilesAction
}) {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const hasOfflineProfiles = Object.keys(storedOfflineProfiles).length !== 0;
  const history = useHistory();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const confirmUserSelection = userId => {
    history.push(`/downloads/${userId}`);
  };

  const handleModal = userId => {
    setSelectedUserId(userId);
    setIsDeleteModalOpen(prev => !prev);
  };

  const handleDeleteProfile = () => {
    deleteOfflineProfilesAction([selectedUserId]);
  };

  const handleKeyDown = (event, userId) => {
    if (event.key === 'Enter') {
      confirmUserSelection(userId);
    }
  };

  const renderUsers = () => (
    <div>
      {Object.entries(storedOfflineProfiles).map(([userId, user], index, array) => (
        <div className={styles.item} key={userId} role="button" tabIndex={0}>
          <div className={styles.topRow}>
            <ContentWithThumbnail
              glyph={GLYPHS.ICON_IMPERSONATION}
              size={thumbnailSizes.MEDIUM}
              text={toInitials(user.firstName, user.lastName)}
              backgroundColor={colors.PROFILE}
              thumbnailClassName={styles.userDetailsThumbnail}
            />
            <div className={styles.nameContainer}>
              <div className={styles.name}>{user.firstName}</div>
              <div className={styles.name}>{user.lastName}</div>
            </div>

            <div className={styles.actions}>
              <a
                href=""
                onClick={event => {
                  event.preventDefault();
                  handleModal(userId);
                }}
              >
                <div className={styles.deleteButton}>
                  <div className={styles.deleteButtonContainer}>
                    <SVGIcon glyph={GLYPHS.ICON_DELETE} />
                    <div className={styles.deleteButtonText}>{offlineContent.remove_from_device}</div>
                  </div>
                </div>
              </a>
              <div
                className={classnames(EmblemSize.MEDIUM, EmblemShape.SQUARE, styles.nextButton)}
                role="button"
                tabIndex="0"
                onClick={() => confirmUserSelection(userId)}
                onKeyDown={event => handleKeyDown(event, userId)}
              >
                <SVGIcon glyph={GlyphIcon.ICON_RIGHT_THICK_16} />
              </div>
            </div>
          </div>
          {index < array.length - 1 && (
            <div className={styles.userDelimiterContainer}>
              <hr className={styles.userDelimiter} />
            </div>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <h1 className={styles.chooseUserTitle}>{offlineContent.choose_user}</h1>
      {hasOfflineProfiles ? (
        <>
          {renderUsers()}
          <OfflineModal
            isOpen={isDeleteModalOpen}
            offlineContent={offlineContent}
            closeOfflineModal={() => handleModal()}
            confirmAction={handleDeleteProfile}
          />
          <OfflineUpdate />
        </>
      ) : (
        <div className={styles.noOfflineProfilesText}>{offlineContent.no_offline_profiles_available}</div>
      )}
    </div>
  );
}

OfflineUserProfiles.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  storedOfflineProfiles: PropTypes.object.isRequired,
  deleteOfflineProfilesAction: PropTypes.func.isRequired
};

const mapStateToProps = ({ offlineContentPlayer }) => ({ storedOfflineProfiles: offlineContentPlayer.profiles });

const mapDispatchToProp = {
  deleteOfflineProfilesAction: deleteOfflineProfiles
};

export default compose(
  withLocalizedContent('productDownload'),
  connect(mapStateToProps, mapDispatchToProp)
)(OfflineUserProfiles);
