import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withLocalizedContent from '../../language/withLocalizedContent.js';
// Style
import styles from './OCPLayout.scss';
import SimpleHeaderStyles from '../StatePages/SimpleHeader/SimpleHeader.scss';
// Redux
// Utils
import withBreakpoint from '../../decorators/withBreakpoint.js';
// Components
import ErrorPage from '../StatePages/ErrorPage/ErrorPage.js';
import OfflineHubHeadersAndBanners from '../HubHeader/OfflineHubHeadersAndBanners.js';
import LoadingPage from '../StatePages/LoadingPage/LoadingPage.js';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
import HubFooter from '../HubFooter/HubFooter.js';
import OcpHomepage from '../../routes/OcpHomepage/OcpHomepage.js';
// Features
import evaluateLayoutProps from './Services/evaluateLayoutProps.js';
import { isEmbeddedInIframe } from '../../utils/platform.js';

function OCPLayout({
  children,
  appError,
  appErrorReference,
  appReady,
  breakpoint,
  localizedContent: { rvsFooterGlossary: hubFooterContent, siteHeaders: hubHeaderContent },
  embedded
}) {
  const isEmbedded = embedded || isEmbeddedInIframe() || sessionStorage.getItem('embedded-by-url') === 'true';

  const [headerProps, setHeaderProps] = useState({});
  const [footerProps, setFooterProps] = useState({});

  useEffect(() => {
    const THEME_NAME = 'ocp-theme';
    document.body.setAttribute('data-theme', THEME_NAME);

    const pathname = window.location.pathname;
    const result = evaluateLayoutProps({
      pathname,
      isEmbedded
    });
    setHeaderProps(result.headerProps);
    setFooterProps(result.footerProps);
  }, []);

  const customLogoComponent = (
    <div className={SimpleHeaderStyles.hubLogo}>
      <SVGIcon glyph={GLYPHS.OUP_LOGO_BLUE} viewBox="0 0 899.28 316.45" />
    </div>
  );

  if (appError) {
    return <ErrorPage appErrorReference={appErrorReference} customLogoComponent={customLogoComponent} />;
  }
  if (!appReady) {
    return <LoadingPage customLogoComponent={customLogoComponent} />;
  }

  const headerPropsExtended = {
    ...headerProps,
    hasPendingInvites: false,
    hideRegisterLink: true,
    hideSignInLink: true,
    breakpoint,
    hubHeaderContent: { ...hubHeaderContent }
  };

  const footerPropsExtended = {
    ...footerProps,
    hubFooterContent: { ...hubFooterContent },
    breakpoint,
    scrollReachBottom: false
  };

  // Due to the structure of the router and the absolute need to have / as the homepage route we check
  // if we have any children, and if none are defined we actually render the ocp homepage
  const childrenToRender = children || <OcpHomepage />;

  return (
    <div className={styles.ocpApp}>
      <div>
        <OfflineHubHeadersAndBanners {...headerPropsExtended} />
        <div className={styles.pageContainer}>
          <main id="maincontent">{childrenToRender}</main>
          {!isEmbedded && <HubFooter {...footerPropsExtended} />}
        </div>
      </div>
    </div>
  );
}

OCPLayout.propTypes = {
  children: PropTypes.any,
  localizedContent: PropTypes.object.isRequired,
  appError: PropTypes.bool.isRequired,
  appErrorReference: PropTypes.string,
  appReady: PropTypes.bool.isRequired,
  userSignedIn: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  role: PropTypes.string,
  breakpoint: PropTypes.string,
  embedded: PropTypes.bool
};

export default compose(
  connect(({ app: { appError, appErrorReference, appReady }, identity: { userId = '', role } }) => ({
    appError,
    appErrorReference,
    appReady,
    userId,
    role,
    userSignedIn: !!role
  })),
  withLocalizedContent('rvsFooterGlossary', 'siteHeaders'),
  withBreakpoint
)(OCPLayout);
