import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import ConnectedHubHeader from './HubHeader';
import HubSubHeader from './HubSubHeader/HubSubHeader';
import BrowserCompatibilityBanner from '../Banners/BrowserCompatibilityBanner/BrowserCompatibilityBanner';
import CookieBanner from '../Banners/CookieBanner/CookieBanner';
import styles from './HubHeadersAndBanners.scss';
import { isHubMode } from '../../utils/platform';
import { openNavigation, closeNavigation } from '../../redux/actions/hubUi';
import { getLinkSocialAccount } from '../../utils/url.js';
import { displayHeaderOrFooter } from '../../services/cptHelperMethods.js';

function runInstantlyAndRepeat(func, interval, amount) {
  // Set timeouts for increasing intervals to run the function
  for (let i = 0; i < amount; i += 1) {
    setTimeout(func, i * interval);
  }
}

class OfflineHubHeadersAndBanners extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
      headerHeight: 61,
      displayHeader: true
    };
  }

  componentDidMount() {
    // Bind events that listen for need to rerender the header
    window.addEventListener('resize', this.handleHeaderHeightChange);
    // Initial run
    this.handleHeaderHeightChange();

    const { history } = this.props;
    this.unlisten = displayHeaderOrFooter(history, this.setDisplay);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  setDisplay = value => {
    this.setState({ displayHeader: value });
  };

  // This has the effect of toggling a class that reveals the <SubHeader> on XS breakpoint:
  onToggleMenu = () => {
    const {
      // eslint-disable-next-line no-shadow
      props: { openNavigation, closeNavigation, hubSideNavOpen }
    } = this;

    if (hubSideNavOpen === true) closeNavigation();
    else openNavigation();
  };

  handleHeaderHeightChange = () => {
    // This negates the issue where componentDidUpdate is called before the DOM has updated
    runInstantlyAndRepeat(this.headerHeightChange, 100, 10);
  };

  headerHeightChange = () => {
    const { headerHeight } = this.state;
    const fixedElement = document.getElementById('fixedHeader');
    const offsetHeight = (fixedElement || { offsetHeight: 61 }).offsetHeight;

    if (headerHeight === offsetHeight) return;

    this.setState({ headerHeight: offsetHeight });
    document.documentElement.style.setProperty('--fixed-header-height', `${offsetHeight}px`);
  };

  render() {
    const {
      customSubHeader,
      orgId,
      isEmbedded,
      hideSignInLink,
      hideRegisterLink,
      hideWidgetDropDown,
      hideSubHeader,
      hideHelpLink,
      disableLogoLink,
      myOrgUrl,
      activityOrgUrl,
      orgChangeUrl,
      hasPendingInvites,
      hideMyAccountText,
      hubSideNavOpen,
      selfSelectRoleModalOpen,
      hideBurgerMenu,
      darkLayout,
      breakpoint,
      hideLanguageSelector,
      hubHeaderContent
    } = this.props;

    const { menuOpen, headerHeight, displayHeader } = this.state;

    const subHeader = customSubHeader || <HubSubHeader menuOpen={menuOpen} />;

    return (
      displayHeader && (
        <div className={classnames({ [styles.headersEmbedded]: isEmbedded })}>
          <div id="fixedHeader" style={{ position: 'fixed', zIndex: 104, width: '100%' }}>
            <div id="banners" role="complementary" className={styles.banners}>
              <CookieBanner handleHeaderHeightChange={this.handleHeaderHeightChange} />
              <BrowserCompatibilityBanner handleHeaderHeightChange={this.handleHeaderHeightChange} />
            </div>
            <ConnectedHubHeader
              orgId={orgId}
              onToggleMenu={this.onToggleMenu}
              hideSignInLink={hideSignInLink}
              hideRegisterLink={hideRegisterLink}
              hideWidgetDropDown={hideWidgetDropDown}
              hideHelpLink={hideHelpLink}
              disableLogoLink={disableLogoLink}
              myOrgUrl={myOrgUrl}
              activityOrgUrl={activityOrgUrl}
              orgChangeUrl={orgChangeUrl}
              hideMyAccountText={hideMyAccountText}
              hideBurgerMenu={hideBurgerMenu}
              darkLayout={darkLayout}
              hubMenuSelected={hubSideNavOpen}
              selfSelectRoleModalOpen={selfSelectRoleModalOpen}
              hasPendingInvites={hasPendingInvites}
              breakpoint={breakpoint}
              linkSocialAccount={getLinkSocialAccount()}
              hideLanguageSelector={hideLanguageSelector}
              isOffline
              offlinePageText={hubHeaderContent.my_download}
            />
          </div>
          <div style={{ height: `${headerHeight}px` }} />
          {!hideSubHeader && !hasPendingInvites ? subHeader : null}
        </div>
      )
    );
  }
}

OfflineHubHeadersAndBanners.propTypes = {
  customSubHeader: PropTypes.element,
  orgId: PropTypes.string,
  isEmbedded: PropTypes.bool.isRequired,
  darkLayout: PropTypes.bool.isRequired,
  hideSubHeader: PropTypes.bool.isRequired,
  hideRegisterLink: PropTypes.bool.isRequired,
  hideSignInLink: PropTypes.bool.isRequired,
  hideWidgetDropDown: PropTypes.bool.isRequired,
  hideHelpLink: PropTypes.bool.isRequired,
  disableLogoLink: PropTypes.bool.isRequired,
  myOrgUrl: PropTypes.string,
  selfSelectRoleModalOpen: PropTypes.bool.isRequired,
  activityOrgUrl: PropTypes.string,
  orgChangeUrl: PropTypes.string,
  hasPendingInvites: PropTypes.bool,
  hideMyAccountText: PropTypes.bool,
  hideBurgerMenu: PropTypes.bool,
  hubSideNavOpen: PropTypes.bool,
  openNavigation: PropTypes.func,
  closeNavigation: PropTypes.func,
  breakpoint: PropTypes.string,
  hideLanguageSelector: PropTypes.bool,
  history: PropTypes.object,
  hubHeaderContent: PropTypes.object
};

// Grab the hubSideNavOpen key only for the Hub application
const mapStateToProps = state => {
  let stateToFetch = {
    orgId: state.identity.currentOrganisationId
  };

  stateToFetch = {
    ...stateToFetch,
    hubSideNavOpen: state.hubUi.sideNavOpen,
    selfSelectRoleModalOpen: state.hubUi.selfSelectRoleModalOpen
  };

  return stateToFetch;
};

// Grab openNavigation and closeNavigation actions only for Hub application
const mapDispatchToProps = isHubMode() ? { openNavigation, closeNavigation } : null;

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(OfflineHubHeadersAndBanners);
